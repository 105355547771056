<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          v-if="allCostSheets.length > 0"
          class="card card-custom gutter-b card-stretch"
        >
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("COSTSHEET.ALL_TOTAL") }}
                <span class="text-muted pt-2 font-size-sm d-block">{{
                  $t("COSTSHEET.LIST_OF_COSTSHEETS")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <div class="mr-2">
                <button
                  @click.prevent="exportXLSX"
                  type="button"
                  :class="{
                    'spinner spinner-primary spinner-right': isExportInProgress,
                  }"
                  disabled
                  class="btn btn-light-primary font-weight-bolder"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="media/svg/icons/Files/Export.svg" />
                  </span>
                  Export to .xlsx
                </button>
              </div>
              <!--end::Dropdown-->
            </div>
          </div>
          <div class="card-body pt-10">
            <div v-if="isAllCostSheetsLoaded" class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          {{ $t("LIST.NAME") }}
                        </th>
                        <th style="min-width: 170px" class="pr-7 text-right">
                          {{ $t("GENERAL.TOTAL") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(costSheet, key) in allCostSheets" :key="key">
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              <span
                                href="#"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              >
                                {{ costSheet.name }}</span
                              ><span class="text-muted font-weight-bold d-block"
                                >{{ costSheet.address }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span
                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >
                            {{ costSheet.total_amount | currencyFormat }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-primary py-4" role="alert">
          {{ $t("COSTSHEET.NO_COSTSHEET_IN_DATABASE") }}
          <router-link
            to="/cost-sheets/new"
            style="
              color: #ffffff;
              float: right;
              font-weight: 600;
              text-decoration: underline;
            "
            >{{ $t("COSTSHEET.COSTSHEET") }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "TotalCostSheets",
  data() {
    return {
      allCostSheets: [],
      isAllCostSheetsLoaded: false,
      isExportInProgress: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALL_COST_SHEETS"), route: "/cost-sheets/list" },
    ]);

    ApiService.get("cost_sheets/total").then(({ data }) => {
      this.allCostSheets = data.costSheets;
      this.isAllCostSheetsLoaded = true;
    });
  },
  methods: {
    exportXLSX() {
      this.isExportInProgress = true;
      ApiService.get("cost_sheets/export").then(({ data }) => {
        this.isExportInProgress = false;
        if (data.status === "ok") {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("COSTSHEET.EXPORT"),
          });
          window.open(data.downloadUrl, "_blank").focus();
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("COSTSHEET.EXPORT_ERR"),
          });
        }
      });
    },
  },
};
</script>
